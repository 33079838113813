import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Head from 'components/head';
import Videos from '../components/videos';

const PaginaVideos = (props) => {
  const { category } = props.pageContext;
  const title = category
    ? `Videos de ${category}`
    : 'Videos de Mapping 3D, Realidad Virtual, animación, aplicaciones y museos';
  const tagName = category
    ? `de ${category} `
    : '';
    // TODO pageImg con titulo de la categoria auto generado
    
  const pageDescription = `Videos ${tagName}realizados por la agencia Visualma, México. Expertos en contenido digital, 3D, animación y programación.`;
  const categoryDescription = `Videos de ${category} realizados por Visualma en nuestros diez años de experiencia como creadores de contenido digital en México.`;

  return (
    <Layout>
      <Head pageTitle={title} pageDescription={pageDescription} />
      <Videos
        title=""
        category={category || ''}
        categoryDescription={categoryDescription}
        serviceUrl={category }
      />
    </Layout>
  );
};
PaginaVideos.propTypes = {
  pageContext: PropTypes.object.isRequired,
  category: PropTypes.string,
};

export default PaginaVideos;

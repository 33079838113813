import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Location } from '@reach/router';
import PropTypes from 'prop-types';
import { Box, SimpleGrid, Heading, Button, Flex, chakra, VisuallyHidden, Icon } from '@chakra-ui/react';
import { MotionBox } from '../constants/poseTemplates';
import { GatsbyImage } from 'gatsby-plugin-image';
import { kebabCase, serviceUrls } from '../helpers/functions';
import TexturaFondo from '../../content/images/texturaFondo.png';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Button3D from '../components/button3D';
import getVideoId from 'get-video-id';
import ReactPlayer from 'react-player/lazy';
import { useMediaQuery } from '@react-hook/media-query';
import posterUrl from '../../content/images/PosterDemoReelVisualmaMoz.jpg';
import { FaPlay } from 'react-icons/fa';


const ButtonD = chakra(Button3D)

const Video = ({ title, imgSrc, url, description, video, index, elVideo }) => {
  const smallDevice = useMediaQuery('(max-width: 600px)');
  const { id, service } =
    typeof video === 'string' ? getVideoId(video) : { id: null, service: null };
  let embedUrl = null;
  let isoDate = '';

  if (video && id && service === 'vimeo') {
    embedUrl = `https://player.vimeo.com/video/${id}`;
  } else if (service === 'youtube') {
    embedUrl = `https://www.youtube-nocookie.com/embed/${id}`;
  }

  return (
    <MotionBox
      //variants={animatedItemVar}
      initial={{ scale: 1 }}
      whileHover={smallDevice ? { scale: 1 } : { scale: 1.05 }}
      whileTap={smallDevice ? { scale: 1 } : { scale: 0.95 }}
      // style={{ height: '265px' }}
      bg="#000"
      // height="265px"
      overflow="hidden"
      borderRadius="24px 24px 8px 8px"
      boxShadow="2px 2px 6px rgba(0, 0, 0, 0.3)"
      cursor="pointer"
      className="video-link"
      
    >
      <GatsbyImage
        image={imgSrc}
        alt={title} 
        objectFit='cover'
        style={{display: 'flex'}}/>

      <Flex direction="row" justifyContent="space-between" mr={3} ml={3}
        textAlign="right" color="#333" height="46px">
        <Flex flexGrow="1" maxWidth="90%">
          <Heading as="h3" textAlign="center"
            className="gradientText gradientText-dark"
            pt={3}
            noOfLines={1}
            isTruncated
            fontSize="0.8em"
            sx={{  "--my-color": "#53c8c4"}}
            style={{ "--index": `${index}` }}
            >
            {title}
          </Heading>
        </Flex>
        <Flex flexBasis="36px" flexGrow="0"
          marginRight="-12px" 
          paddingRight="6px"
          marginBottom="9px"
          marginTop="2px"
          color="white"
          _hover={{
            background: 'transparent',
            color: 'var(--accent-color)',
            // boxShadow: '0px 0px 5px #666'
          }}>
          <Link to={url ? url : ''}
            style={{
              // background: 'red',
              padding: '6px',
              marginTop: '0',
              marginRight: '-12px',
              paddingRight: '0px',
              marginBottom: '6px',
              width: '100%'
            }} 
            aria-label={title}
            >
              <VisuallyHidden>{title}</VisuallyHidden>
            </Link>
        </Flex>
      </Flex>
      
    </MotionBox>
  );
}
Video.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.object.isRequired,
  description: PropTypes.string,
  fecha: PropTypes.string,
  cliente: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const Videos = ({
  title = 'Videos',
  category = '',
  categoryDescription = '',
  limit = 300,
}) => {
  const data = useStaticQuery(graphql`query Videos {
  proyectos: allMdx(
    filter: {
      fields: {collection: {eq: "proyectos"}}, frontmatter: {video:{ne:null}}
    }
    sort: {order: DESC, fields: [frontmatter___date]}
    limit: 1000
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          description
          video
          tags
          image {
            childImageSharp {
              gatsbyImageData(width: 280, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }

  tags: allMdx(filter: {fields: {collection: {eq: "proyectos"}}, frontmatter: {video:{ne:null}}}, limit: 2000) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }
}
`);
  const tags = data.tags.group;
  let proyectos = category
    ? data.proyectos.edges
        .filter((item) => item.node.frontmatter.tags.indexOf(category) != -1)
        .filter((_v, i) => i < limit)
    : data.proyectos.edges.filter((_v, i) => i < limit);

  // const playerRef = React.createRef()
  const [videoUrl, setVideoUrl] = useState('https://vimeo.com/459961237')
  const [light, setLight] = useState(posterUrl)

  return (
    <React.Fragment>
      <Location>
        {({ location }) => {
          const inProjectPage = location.pathname.match(
            /^\/videos\/?[a-z]*/
          );

          return <>
            {inProjectPage ? (
              <Box
                background="linear-gradient(160deg,#130202,#1c0516,#230823,#201025,#161821,#14293a,#1e5397,#0082ff);"
                // background={`#000 url(${TexturaFondo}) 50px 30px`}
                backgroundn="linear-gradient(to right, rgb(35, 35, 35), rgb(53, 53, 53))"
              >
                <Box m="0" overflowX="scroll">
                  <Box
                    width={['max-content', 'max-content']}
                    borderRadius={[
                      '0 0 6px 6px',
                      '0 0 6px 6px',
                      '0 0 0 12px',
                    ]}
                    background="#fff"
                    textAlign="center"
                    margin={['0 0 0em auto', '0 0 0 auto']}
                    padding={[0, 0, '0 0 0 6px']}
                    borderTop={[
                      'none',
                      'none',
                      '4px solid var(--accent-color)',
                    ]}
                  >
                    {/* position={["inherit", "inherit", "absolute"]} top="53px" right="0" zIndex="1" */}
                    {tags
                      .sort((a, b) => (a.fieldValue < b.fieldValue ? 1 : -1))
                      .map((tag) => {
                        const active =
                          kebabCase(category) === kebabCase(tag.fieldValue);
                        return (
                          <Button
                            as={Link}
                            key={tag.fieldValue}
                            to={`/videos/${kebabCase(tag.fieldValue)}`}
                            size="xs"
                            m="6px 6px"
                            variant={active ? 'solid' : 'outline'}
                            colorScheme={active ? 'azulin' : 'gray'}
                          >
                            {tag.fieldValue === 'Aplicaciones y Videojuegos'
                              ? 'Apps'
                              : tag.fieldValue === 'Video Mapping 3D'
                              ? 'Mapping 3D'
                              : tag.fieldValue}{' '}
                            {`(${tag.totalCount})`}
                          </Button>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            ) : null}

            <Box
              // background={`#222 url(${TexturaFondo})`}
              background="linear-gradient(160deg,#130202,#1c0516,#230823,#201025,#161821,#14293a,#1e5397,#0082ff);"
              marginTop={0}
              padding={['0.5em 12px 2rem 12px', '0.5em 2rem 2rem 2rem', '0.5em 2rem 2rem 2rem', '0.5em 4rem 4rem 4rem']}
            >
              <Content>
                <Flex 
                  marginBottom="2em" 
                  paddingTop={['0']}
                  marginTop={['0', '1em', '1em']}
                  height={['212px', '350px', '391px']}
                  background="#000"
                >
                  <div className="playerWrapper">
                    <ReactPlayer
                      id="mainPlayer"
                      // ref={playerRef}
                      url={videoUrl}
                      loop={false}
                      controls={true}
                      light={light}
                      playIcon={<Icon as={FaPlay} w={20} h={20} color='white' style={{filter: 'drop-shadow(6px 6px 6px  black)'}}
                        title={'Play video'} aria-label={'Play Video'} />}

                      width='100%'
                      className='reactPlayer'
                      playsinline={true}
                      autoPlay={true}
                      playing={true}
                      pip={false}
                      config={{
                        youtube: {
                          playerVars: {
                            showinfo: 0,
                            byline: 0,
                            portrait: 0,
                            vq: 'hd1080',
                            modestbranding: 1,
                            color: '4895b9',
                            iv_load_policy: 3,
                            rel: 0,
                            mute: 1,
                            // autoplay: 0
                          },
                        },
                        vimeo: {
                          playerOptions: {
                            muted: true,
                            // autoplay: false,
                            loop: true,
                            dnt: true,
                            pip: false,
                            portrait: false,
                            quality: '1080p',
                            responsive: false
                          }
                        }
                      }}
                      // onEnded={() => console.log('mainPlayer ended')}
                    />
                  </div>
                </Flex>

                <Flex overflow="scroll" 
                  height={['50vh', '40vh']}
                  //height: 60vh;
                  // bottom: 166px;
                  // position: absolute;
                  // position: fixed;
                  // max-width: 70em;
                >
                  <SimpleGrid
                    columns={[2, 3, 4, 5, 6]}
                    spacing={['6px', '24px', '12px', '12px']}
                    paddingBottom="4rem"
                  >
                    {proyectos.map((proyecto, i) => {
                      const datos = proyecto.node.frontmatter;
                      const slug = proyecto.node.fields.slug;

                      return (
                        <React.Fragment key={`proyecto_${i}`}>
                          <Box to={'#'} onClick={() => { setLight(true); setVideoUrl(datos.video); }}>
                            <Video
                              title={datos.title}
                              url={`/proyectos${slug}`}
                              imgSrc={datos.image.childImageSharp.gatsbyImageData}
                              description={datos.description}
                              video={datos.video}
                              index={i}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    })}
                  </SimpleGrid>
                </Flex>


                {(title || category) && (
                  <h1 style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: 1.2, color: '#2194d8', maxWidth: '36em', margin: '1.6em auto 0' }}>
                    Videos de {title || category}
                  </h1>
                )}
                {category && categoryDescription && (
                  <div
                    className="parrafoChico"
                    style={{
                      color: '#cac9c9',
                      marginBottom: '1em',
                      padding: '1.6em',
                    }}
                  >
                    {categoryDescription}
                    <Box textAlign="right" mt="2em" mr="1em">
                      <Link
                        to={serviceUrls[kebabCase(category)]}
                      >
                        <ButtonD dark={true}>
                          Mas información de {category}&nbsp;→
                          {/* <ChevronRightIcon /> */}
                        </ButtonD>
                      </Link>
                    </Box>
                  </div>
                )}

              </Content>

              {!inProjectPage ? (
                <Box textAlign="center" m="3rem 0 6em">
                  <Button
                    className="btnCta"
                    as={Link}
                    to={
                      category
                        ? `/proyectos/${kebabCase(category)}`
                        : '/proyectos'
                    }
                    textDecoration="none"
                    colorScheme="azulin"
                    size="lg"
                    rightIcon={<ExternalLinkIcon ml="2"/>}
                  >
                    Ver más videos {category ? ` de ${category}` : ''}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </>;
        }}
      </Location>
    </React.Fragment>
  );
};
Videos.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  categoryDescription: PropTypes.string,
  limit: PropTypes.number,
};

const Content = styled.div`
  img {
    max-width: 100%;
  }
  h3 {
    color: var(--accent-color-txt);
    font-size: calc(0.8em + 0.2vw);
    line-height: 1.2;
    font-weight: 700;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    /* text-shadow: rgb(246, 243, 242) 3px 0px 0px, rgb(246, 243, 242) 2px 0px 0px,
      rgb(246, 243, 242) 1px 0px 0px, rgb(246, 243, 242) -1px 0px 0px,
      rgb(246, 243, 242) -2px 0px 0px, rgb(246, 243, 242) -3px 0px 0px; */
    text-size-adjust: 100%;
    padding: 0;
    margin: 6px 0 8px 0;
    text-align: left;
    /* text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.06); */
  }
  .descripcion {
    font-size: calc(0.89em + 0.2vw);
    line-height: calc(1.38em + 0.2vw);
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
  }
  a {
    text-decoration: none;
  }

  .playerWrapper {
    position: relative;
    //padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
    //margin-bottom: 2em;
    width: 100%;
  }

  .reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    box-shadow: 0px 0px 4px 0px #a9a6a6bb;
    height: 196px !important;

    @media (min-width: 30em) {
      height: 330px !important;
    }
    @media (min-width: 48em) {
      height: 391px !important;
    }

  }

  @-webkit-keyframes gradient-data {
    0% {
      background-position: 0 50%
    }

    50% {
      background-position: 100% 50%
    }

    to {
      background-position: 0 50%
    }
  }

  @keyframes gradient-data {
    0% {
      background-position: 0 50%
    }

    50% {
      background-position: 100% 50%
    }

    to {
      background-position: 0 50%
    }
  }




  .gradientText {
    background: linear-gradient(141.27deg,#00c3ff -4.24%,#fff 21.25%,#0f647e 32.33%,#0a78b9 53.46%, #fff 73.46%);
    background-size: 200%;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-animation: gradient-data 10s ease infinite;
    animation: gradient-data 10s ease infinite;
    -webkit-text-fill-color: transparent;
    will-change: background-position;

    color: #0a78b9;
    font-weight: 400;
    letter-spacing: -.04em;

    font-size: 0.8em;
    white-space: unset;

    /* background-size: 100%;
    -webkit-background-clip: text;*/
    font-weight: bold;
    letter-spacing: -0.01em;

    --index: 0;
    /* background-size: calc(300% - 10px * var(--index)); */
    animation-delay: calc(0.3s * var(--index));
    /* -webkit-animation-delay: calc(0.025s * var(--index)); */
  }
  /* .gradientText-dark {
    background-color: #ff9800;
    background-image: linear-gradient(
    141.27deg
    ,#000000 -4.24%,#ff5982 21.25%,#ff9800 44.33%,#79e2ff 83.46%);
  } */

.video-link {
  position: relative;
  float: left;
}
.video-link img {
  display: block;
}
.video-link:after, .video-link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
}
.video-link:after {
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-left: 28px solid #fff;
  margin-top: -38px;
  margin-left: -7px;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.9));
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.9));
}
.video-link:hover:after {
  border-left-color: var(--accent-color);
}
/* .video-link:before {
  margin-top: -34px;
  margin-left: -34px;
  width: 60px;
  height: 60px;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
  background: rgba(0, 0, 0, 0.42);
}
.video-link:hover:before {
  background: rgba(0, 0, 0, 0.7);
} */

`;

export default Videos;
